import PalletsTab from "@/pages/ProjectsPage/ProejctDialog/ViewProjectDialog/PalletsTab.tsx";
import type { Project } from "@/queries/project.ts";
import CloseIcon from "@mui/icons-material/Close";
import {
    AppBar,
    Box,
    Dialog,
    Divider,
    IconButton,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from "@mui/material";
import { type ReactNode, useState } from "react";
import DocumentsTab from "./DocumentsTab.tsx";

type Props = {
    project: Project;
    open: boolean;
    onClose: () => void;
    onExited: () => void;
};

const ViewProjectDialog = ({ project, open, onClose, onExited }: Props): ReactNode => {
    const [activeTab, setActiveTab] = useState("pallets");

    return (
        <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }} fullScreen>
            <AppBar>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2 }} variant="h6" component="div">
                        {project.name}
                    </Typography>
                </Toolbar>
                <Divider />
                <Tabs
                    value={activeTab}
                    onChange={(_event, value) => {
                        setActiveTab(value);
                    }}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="inherit"
                >
                    <Tab label="Pallets" value="pallets" />
                    <Tab label="Documents" value="documents" />
                </Tabs>
            </AppBar>
            <Toolbar />
            <Divider />
            <Tabs />

            <Box sx={{ py: 3 }}>
                {activeTab === "pallets" && <PalletsTab project={project} />}
                {activeTab === "documents" && <DocumentsTab project={project} />}
            </Box>
        </Dialog>
    );
};

export default ViewProjectDialog;
