import { useUserInformationContext } from "@/components/UserInformationProvider/index.ts";
import { type User, useAuth0 } from "@auth0/auth0-react";
import { Divider, ListItem, ListItemText, Menu, MenuItem } from "@mui/material";
import { type PopupState, bindMenu } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import ModeSwitch from "./ModeSwitch.tsx";

type Props = {
    state: PopupState;
    user: User;
};

const UserMenu = ({ state, user }: Props): ReactNode => {
    const userInformation = useUserInformationContext();
    const { logout } = useAuth0();

    return (
        <Menu
            {...bindMenu(state)}
            slotProps={{
                paper: { sx: { width: 400 } },
            }}
        >
            <ListItem>
                <ListItemText
                    primaryTypographyProps={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                    secondaryTypographyProps={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                    primary={user.email}
                    secondary={userInformation.type === "customer" && userInformation.vendor.name}
                />
            </ListItem>
            <Divider key="divider" sx={{ mb: 1 }} />
            <ListItem>
                <ModeSwitch />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <MenuItem
                onClick={() => {
                    state.close();
                    void logout();
                }}
            >
                Log out
            </MenuItem>
        </Menu>
    );
};

export default UserMenu;
