import { projectDocumentTypeMap } from "@/components/Project/documents.ts";
import { useDocumentDownload } from "@/components/Project/index.ts";
import type { Document, Project } from "@/queries/project.ts";
import DownloadIcon from "@mui/icons-material/Download";
import { Backdrop, CircularProgress, ListItemButton, ListItemText } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    document: Document;
    project: Project;
};

const DocumentListItem = ({ document, project }: Props): ReactNode => {
    const { downloading, startDownload } = useDocumentDownload(project.id, document.id);

    return (
        <ListItemButton onClick={startDownload}>
            <ListItemText
                primaryTypographyProps={{ sx: { wordBreak: "break-word" } }}
                primary={document.filename}
                secondary={projectDocumentTypeMap[document.type].label}
            />
            <DownloadIcon />
            <Backdrop open={downloading}>
                <CircularProgress />
            </Backdrop>
        </ListItemButton>
    );
};

export default DocumentListItem;
