import {
    type ProjectDocumentTypeKey,
    projectDocumentTypeKeys,
} from "@/components/Project/documents.ts";
import { type ProjectStatusKey, projectStatusKeys } from "@/components/Project/index.ts";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseQueryResult, keepPreviousData, useQuery } from "@tanstack/react-query";
import {
    type PageParams,
    type Relationships,
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
    injectPageParams,
} from "jsonapi-zod-query";
import { z } from "zod";

const projectAttributesSchema = z.object({
    name: z.string(),
    status: z.enum(projectStatusKeys as [ProjectStatusKey, ...ProjectStatusKey[]]),
});

const vendorRelationship = {
    relationshipType: "one",
    include: {
        type: "vendor",
        attributesSchema: z.object({
            name: z.string(),
        }),
    },
} satisfies Relationships[string];

const projectSelector = createDataSelector(
    createResourceSelector({
        type: "project",
        attributesSchema: projectAttributesSchema,
        relationships: {
            vendor: vendorRelationship,
        },
    }),
);

const projectsSelector = createResourceCollectionSelector({
    type: "project",
    attributesSchema: projectAttributesSchema.pick({
        name: true,
        status: true,
    }),
    relationships: {
        vendor: vendorRelationship,
    },
});

export type Project = ReturnType<typeof projectSelector>;
export type PaginatedProjects = ReturnType<typeof projectsSelector>;
export type ListProject = PaginatedProjects["data"][number];

export const useProjectQuery = (id: string): UseQueryResult<Project> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["project", id],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl(`/projects/${id}`), {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: projectSelector,
    });
};

export const useProjectsQuery = (
    pageParams: PageParams | undefined,
    filterSearch = "",
    filterStatus: string | null = null,
    filterVendor: string | null = null,
): UseQueryResult<PaginatedProjects> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["projects", { pageParams, filterSearch, filterStatus, filterVendor }],
        queryFn: async ({ signal }) => {
            const url = apiUrl("/projects");
            url.searchParams.set("fields[project]", "name,status,vendor");
            url.searchParams.set("fields[vendor]", "name");
            url.searchParams.set("include", "vendor");

            if (filterSearch.length > 0) {
                url.searchParams.set("filter[search]", filterSearch);
            }

            if (filterStatus) {
                url.searchParams.set("filter[status]", filterStatus);
            }

            if (filterVendor) {
                url.searchParams.set("filter[vendor]", filterVendor);
            }

            injectPageParams(url, pageParams);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: projectsSelector,
        placeholderData: keepPreviousData,
    });
};

const palletAttributesSchema = z.object({
    name: z.string(),
    type: z.string(),
    serialNumber: z.string(),
    weight: z.number(),
    numberOfItems: z.number().nullable(),
    photoObjectKeys: z.array(z.string()),
});

const palletsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "pallet",
        attributesSchema: palletAttributesSchema,
    }),
);

export type Pallet = ReturnType<typeof palletsSelector>[number];

export const usePalletsQuery = (projectId: string): UseQueryResult<Pallet[]> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["project", projectId, "pallets"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl(`/projects/${projectId}/pallets`), {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: palletsSelector,
    });
};

const documentAttributesSchema = z.object({
    type: z.enum(projectDocumentTypeKeys as [ProjectDocumentTypeKey, ...ProjectDocumentTypeKey[]]),
    filename: z.string(),
    objectKey: z.string(),
});

const documentsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "document",
        attributesSchema: documentAttributesSchema,
    }),
);

export type Document = ReturnType<typeof documentsSelector>[number];

export const useDocumentsQuery = (projectId: string): UseQueryResult<Document[]> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["project", projectId, "documents"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl(`/projects/${projectId}/documents`), {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: documentsSelector,
    });
};
