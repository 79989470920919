import { projectDocumentTypeMap } from "@/components/Project/documents.ts";
import { useDocumentDownload } from "@/components/Project/index.ts";
import { useDeleteProjectDocumentMutation } from "@/mutations/project.ts";
import type { Document, Project } from "@/queries/project.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Backdrop,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    document: Document;
    project: Project;
};

const DocumentListItem = ({ document, project }: Props): ReactNode => {
    const deleteMutation = useDeleteProjectDocumentMutation();
    const { downloading, startDownload } = useDocumentDownload(project.id, document.id);
    const isLoading = deleteMutation.isPending || downloading;

    return (
        <ListItem
            secondaryAction={
                <>
                    <IconButton
                        onClick={() => {
                            deleteMutation.mutate({
                                projectId: project.id,
                                documentId: document.id,
                            });
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            }
            disablePadding
        >
            <ListItemButton onClick={startDownload}>
                <ListItemText
                    primaryTypographyProps={{ sx: { wordBreak: "break-word" } }}
                    primary={document.filename}
                    secondary={projectDocumentTypeMap[document.type].label}
                />
            </ListItemButton>

            <Backdrop open={isLoading}>
                <CircularProgress />
            </Backdrop>
        </ListItem>
    );
};

export default DocumentListItem;
