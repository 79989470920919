import PhotoScroller from "@/pages/ProjectsPage/ProejctDialog/ViewProjectDialog/PhotoScroller.tsx";
import type { Pallet, Project } from "@/queries/project.ts";
import { numberFormatter } from "@/utils/format.ts";
import { Card, CardContent, CardHeader, CardMedia } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    project: Project;
    pallet: Pallet;
};

const PalletCard = ({ project, pallet }: Props): ReactNode => {
    return (
        <Card sx={{ height: "100%" }}>
            <CardHeader title={pallet.name} />
            <CardMedia component={PhotoScroller} project={project} pallet={pallet} />
            <CardContent>
                Number: {pallet.serialNumber}
                <br />
                Weight: {numberFormatter.format(pallet.weight)} lbs
                <br />
                {pallet.numberOfItems !== null && (
                    <>
                        Items: {numberFormatter.format(pallet.numberOfItems)}
                        <br />
                    </>
                )}
                Type: {pallet.type}
            </CardContent>
        </Card>
    );
};

export default PalletCard;
