import { projectStatusMap } from "@/components/Project/index.ts";
import type { ListProject } from "@/queries/project.ts";
import { Chip, List, ListItemButton, ListItemText } from "@mui/material";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    projects: ListProject[];
    showVendor?: boolean;
};

const ProjectsList = ({ projects, showVendor }: Props): ReactNode => {
    const navigate = useNavigate();

    return (
        <List disablePadding>
            {projects.map((project) => (
                <ListItemButton
                    key={project.id}
                    onClick={() => {
                        navigate(`/projects/${project.id}`);
                    }}
                >
                    <ListItemText
                        primary={project.name}
                        secondary={showVendor && project.vendor.name}
                    />
                    <Chip label={projectStatusMap[project.status].label} />
                </ListItemButton>
            ))}
        </List>
    );
};

export default ProjectsList;
