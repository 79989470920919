export type ProjectDocumentType = {
    key: string;
    label: string;
};

export const projectDocumentTypes = [
    { key: "project_weight", label: "Project Weight" },
    { key: "data_destruction_certificate", label: "Cert of Data Destruction" },
    { key: "removal_receipt", label: "Removal Receipt" },
    { key: "environment_impact_report", label: "Environment Impact Report" },
    { key: "settlement_report", label: "Settlement Report" },
    { key: "locked_lists", label: "Locked Lists" },
] as const satisfies ProjectDocumentType[];

export type ProjectDocumentTypeKey = (typeof projectDocumentTypes)[number]["key"];

export const projectDocumentTypeKeys = projectDocumentTypes.map((attributes) => attributes.key);
export const projectDocumentTypeMap = Object.fromEntries(
    projectDocumentTypes.map((documentType) => [documentType.key, documentType]),
) as Record<ProjectDocumentTypeKey, ProjectDocumentType>;
