import type { Vendor } from "@/queries/vendor.ts";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { formatAddress } from "localized-address-format";
import type { ReactNode } from "react";

type Props = {
    vendor: Vendor;
};

const ContactCard = ({ vendor }: Props): ReactNode => {
    return (
        <Card>
            <CardHeader title="Contact" subheader={vendor.contact.fullName} />
            <CardContent>
                <Typography whiteSpace="pre-line" mb={2}>
                    {formatAddress({
                        addressLines: [vendor.address.streetAddress],
                        locality: vendor.address.city,
                        postalCode: vendor.address.zipCode,
                        administrativeArea: vendor.address.state,
                        postalCountry: "US",
                    }).join("\n")}
                </Typography>
                <Typography>Phone: {vendor.contact.phoneNumber.formatNational()}</Typography>
            </CardContent>
        </Card>
    );
};

export default ContactCard;
