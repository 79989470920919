import type { Pallet, Project } from "@/queries/project.ts";
import { useSignedObjectUrlsQuery } from "@/queries/signed-object-url.ts";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Fab, Skeleton } from "@mui/material";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
    project: Project;
    pallet: Pallet;
};

const PhotoScroller = ({ project, pallet }: Props): ReactNode => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const { ref, inView } = useInView();
    const [enabled, setEnabled] = useState(false);
    const [photoObjectUrls, setPhotoObjectUrls] = useState<string[] | null>(null);
    const isPreloading = useRef(false);

    useEffect(() => {
        if (inView) {
            setEnabled(true);
        }
    }, [inView]);

    const signedObjectUrlsQuery = useSignedObjectUrlsQuery(
        `/projects/${project.id}/pallets/${pallet.id}/photos`,
        enabled && pallet.photoObjectKeys.length > 0,
    );

    useEffect(() => {
        if (!signedObjectUrlsQuery.data || isPreloading.current) {
            return;
        }

        isPreloading.current = true;

        (async () => {
            const photoObjectUrls = (
                await Promise.all(
                    signedObjectUrlsQuery.data.map(async (url) => {
                        const response = await fetch(url.$links.self);

                        if (!response.ok) {
                            return null;
                        }

                        return URL.createObjectURL(await response.blob());
                    }),
                )
            ).filter((url): url is string => url !== null);

            setPhotoObjectUrls(photoObjectUrls);
            isPreloading.current = false;
        })();
    }, [signedObjectUrlsQuery.data]);

    if (pallet.photoObjectKeys.length === 0 || !photoObjectUrls) {
        return (
            <Skeleton
                ref={ref}
                animation={pallet.photoObjectKeys.length > 0 ? undefined : false}
                variant="rectangular"
                width="100%"
                height={200}
            />
        );
    }

    if (signedObjectUrlsQuery.isError) {
        throw signedObjectUrlsQuery.error;
    }

    const navigate = (delta: number) => {
        let newIndex = photoIndex + delta;
        const maxIndex = photoObjectUrls.length - 1;

        if (newIndex < 0) {
            newIndex = maxIndex;
        } else if (newIndex > maxIndex) {
            newIndex = 0;
        }

        setPhotoIndex(newIndex);
    };

    return (
        <Box
            ref={ref}
            sx={{
                position: "relative",
                width: "100%",
                height: 200,
                overflow: "hidden",
            }}
        >
            {photoObjectUrls.map((url, index) => (
                <Box
                    key={url}
                    sx={{
                        display: index === photoIndex ? "block" : "none",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url("${url}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                    }}
                />
            ))}

            {photoObjectUrls.length > 1 && (
                <>
                    <Fab
                        size="small"
                        sx={{
                            position: "absolute",
                            left: 8,
                            top: "50%",
                            marginTop: "-18px",
                        }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <KeyboardArrowLeftIcon />
                    </Fab>
                    <Fab
                        size="small"
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: "50%",
                            marginTop: "-18px",
                        }}
                        onClick={() => {
                            navigate(1);
                        }}
                    >
                        <KeyboardArrowRightIcon />
                    </Fab>
                </>
            )}
        </Box>
    );
};

export default PhotoScroller;
