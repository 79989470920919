import { TextField } from "@mui/material";
import type { TextFieldProps } from "@mui/material";
import { type ChangeEvent, type ReactNode, useEffect, useRef, useState } from "react";

type Props = Omit<TextFieldProps, "onChange" | "value"> & {
    delay: number;
    onChange: (value: string) => void;
    value: string;
};

const DebouncedTextField = ({ delay, onChange, value, ...textFieldProps }: Props): ReactNode => {
    const [currentValue, setCurrentValue] = useState(value);
    const timerRef = useRef<number>();

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timerRef.current);
        setCurrentValue(event.target.value);

        timerRef.current = setTimeout(() => {
            onChange(event.target.value);
        }, delay);
    };

    return <TextField {...textFieldProps} value={currentValue} onChange={handleChange} />;
};

export default DebouncedTextField;
