import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Toolbar } from "@mui/material";
import type { PageParams } from "jsonapi-zod-query";
import type { ReactNode } from "react";

type Props = {
    onChange: (pageParams: PageParams) => void;
    pageParams?: {
        first?: PageParams | null;
        prev?: PageParams | null;
        next?: PageParams | null;
    };
};

const PageParamsNavigation = ({ pageParams, onChange }: Props): ReactNode => {
    return (
        <Toolbar sx={{ justifyContent: "center" }}>
            <Button
                startIcon={<KeyboardArrowLeftIcon />}
                disabled={!pageParams?.prev}
                onClick={() => {
                    if (pageParams?.prev) {
                        onChange(pageParams.prev);
                    }
                }}
            >
                Previous
            </Button>
            <Button
                endIcon={<KeyboardArrowRightIcon />}
                disabled={!pageParams?.next}
                onClick={() => {
                    if (pageParams?.next) {
                        onChange(pageParams.next);
                    }
                }}
            >
                Next
            </Button>
        </Toolbar>
    );
};

export default PageParamsNavigation;
