import { useProjectsQuery } from "@/queries/project.ts";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
    Card,
    CardContent,
    CardHeader,
    LinearProgress,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

const RecentProjectsCard = (): ReactNode => {
    const projectsQuery = useProjectsQuery({ size: "3" });
    const navigate = useNavigate();

    if (projectsQuery.isPending) {
        return (
            <Card sx={{ flexGrow: 1 }}>
                <CardHeader title="Recent projects" />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
        );
    }

    if (projectsQuery.isError) {
        throw projectsQuery.error;
    }

    const projects = projectsQuery.data.data;

    return (
        <Card sx={{ flexGrow: 1 }}>
            <CardHeader title="Recent projects" />
            <CardContent sx={{ px: 0 }}>
                {projects.length === 0 ? (
                    <Typography>No recent projects</Typography>
                ) : (
                    <List disablePadding>
                        {projects.map((project) => (
                            <ListItemButton
                                key={project.id}
                                onClick={() => {
                                    navigate(`/projects/${project.id}`);
                                }}
                            >
                                <ListItemText primary={project.name} />
                                <KeyboardArrowRightIcon />
                            </ListItemButton>
                        ))}
                    </List>
                )}
            </CardContent>
        </Card>
    );
};

export default RecentProjectsCard;
