import logoColor from "@/assets/logo-color.svg";
import logoWhite from "@/assets/logo-white.svg";
import { useUserInformationContext } from "@/components/UserInformationProvider/index.ts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderIcon from "@mui/icons-material/Folder";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
} from "@mui/material";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

type NavigationItem = {
    href: string;
    label: string;
    icon: ReactNode;
    allowedRoles: ("customer" | "employee")[];
};

const navigationItems: NavigationItem[] = [
    {
        label: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
        allowedRoles: ["customer"],
    },
    {
        label: "Projects",
        href: "/projects",
        icon: <FolderIcon />,
        allowedRoles: ["customer", "employee"],
    },
];

const DrawerContent = (): ReactNode => {
    const userInformation = useUserInformationContext();
    const {
        palette: { mode },
    } = useTheme();

    return (
        <>
            <Box
                component="img"
                src={mode === "dark" ? logoWhite : logoColor}
                height={30}
                alt="Sycamore International"
                sx={{ my: 2 }}
            />
            <Divider variant="middle" />

            <List>
                {navigationItems
                    .filter((item) => item.allowedRoles.includes(userInformation.type))
                    .map((item) => (
                        <ListItem key={item.href} disablePadding>
                            <ListItemButton component={Link} to={item.href}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </>
    );
};

export default DrawerContent;
