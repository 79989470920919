import Layout from "@/components/Layout/index.ts";
import RootErrorBoundary from "@/components/RootErrorBoundary/index.ts";
import UserInformationProvider from "@/components/UserInformationProvider/index.ts";
import DashboardPage from "@/pages/DashboardPage/index.ts";
import HomePage from "@/pages/HomePage/index.ts";
import ProjectsPage, { ProjectDialog } from "@/pages/ProjectsPage/index.ts";
import { type RouteObject, createBrowserRouter } from "react-router-dom";

const pathRoutes: RouteObject[] = [
    {
        index: true,
        element: <HomePage />,
    },
    {
        path: "dashboard",
        element: <DashboardPage />,
    },
    {
        path: "projects",
        element: <ProjectsPage />,
        children: [
            {
                path: ":projectId",
                element: <ProjectDialog />,
            },
        ],
    },
];

const rootRoute: RouteObject = {
    path: "/",
    element: (
        <UserInformationProvider>
            <Layout />
        </UserInformationProvider>
    ),
    errorElement: <RootErrorBoundary />,
    children: pathRoutes,
};

export const router = createBrowserRouter([rootRoute]);

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}
