import { parsePhoneNumberWithError } from "libphonenumber-js";
import { z } from "zod";

export const phoneNumberSchema = z.string().transform((value, context) => {
    try {
        return parsePhoneNumberWithError(value);
    } catch {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid phone number",
        });
        return z.NEVER;
    }
});
