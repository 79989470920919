import { type ListVendor, useVendorsQuery } from "@/queries/vendor.ts";
import { Autocomplete, type SxProps, TextField } from "@mui/material";
import { type ReactNode, type SyntheticEvent, useRef, useState } from "react";

type Props = {
    value: ListVendor | null;
    onChange: (value: ListVendor | null) => void;
    sx?: SxProps;
};

const VendorFilter = ({ value, onChange, sx }: Props): ReactNode => {
    const [filterValue, setFilterValue] = useState("");
    const timerRef = useRef<number>();

    const handleInputChange = (_event: SyntheticEvent, newInputValue: string) => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            setFilterValue(newInputValue);
        }, 200);
    };

    const vendorsQuery = useVendorsQuery(
        { number: "0", size: "50" },
        filterValue,
        filterValue !== "",
    );

    return (
        <Autocomplete
            value={value}
            onChange={(_event, value) => {
                onChange(value);
            }}
            options={filterValue !== "" ? vendorsQuery.data?.data ?? [] : []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={filterValue !== "" && vendorsQuery.isPending}
            noOptionsText={filterValue === "" ? "Type to search" : "No vendors found"}
            renderInput={(params) => <TextField label="Vendor" {...params} />}
            autoHighlight
            sx={sx}
            size="small"
            onInputChange={handleInputChange}
        />
    );
};

export default VendorFilter;
