import { useUserInformationContext } from "@/components/UserInformationProvider/index.ts";
import { Grid, Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";
import AccountManagerCard from "./AccountManagerCard.tsx";
import ContactCard from "./ContactCard.tsx";
import RecentProjectsCard from "./RecentProjectsCard.tsx";

const DashboardPage = (): ReactNode => {
    const userInformation = useUserInformationContext();

    if (userInformation.type !== "customer") {
        throw new Error("Dashboard is only available to customers");
    }

    const vendor = userInformation.vendor;

    return (
        <>
            <Typography variant="h5">Dashboard</Typography>
            <Typography variant="h6" mb={2}>
                {vendor.name}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Stack spacing={2}>
                        <ContactCard vendor={vendor} />
                        <AccountManagerCard accountManager={vendor.accountManager} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <RecentProjectsCard />
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardPage;
