import DebouncedTextField from "@/components/DebouncedTextField/index.ts";
import ClearIcon from "@mui/icons-material/Clear.js";
import SearchIcon from "@mui/icons-material/Search.js";
import { IconButton, InputAdornment, type SxProps } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    label: string;
    value: string;
    onChange: (value: string) => void;
    sx?: SxProps;
};

const SearchField = ({ label, value, onChange, sx }: Props): ReactNode => {
    return (
        <DebouncedTextField
            delay={200}
            onChange={onChange}
            label={label}
            size="small"
            sx={sx}
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: value !== "" && (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => {
                                onChange("");
                            }}
                            edge="end"
                        >
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchField;
