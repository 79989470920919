import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { accountManagerAttributesSchema } from "@/queries/account-manager.ts";
import { apiUrl } from "@/utils/api.ts";
import { phoneNumberSchema } from "@/utils/zod.ts";
import { type UseQueryResult, keepPreviousData, useQuery } from "@tanstack/react-query";
import {
    type PageParams,
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
    injectPageParams,
} from "jsonapi-zod-query";
import { z } from "zod";

const vendorAttributesSchema = z.object({
    name: z.string(),
    address: z.object({
        streetAddress: z.string(),
        city: z.string(),
        state: z.string(),
        zipCode: z.string(),
    }),
    contact: z.object({
        fullName: z.string(),
        phoneNumber: phoneNumberSchema,
    }),
});

const vendorSelector = createDataSelector(
    createResourceSelector({
        type: "vendor",
        attributesSchema: vendorAttributesSchema,
        relationships: {
            accountManager: {
                relationshipType: "one",
                include: {
                    type: "account_manager",
                    attributesSchema: accountManagerAttributesSchema,
                },
            },
        },
    }),
);

const vendorsSelector = createResourceCollectionSelector({
    type: "vendor",
    attributesSchema: vendorAttributesSchema.pick({
        name: true,
    }),
});

export type Vendor = ReturnType<typeof vendorSelector>;
export type PaginatedVendors = ReturnType<typeof vendorsSelector>;
export type ListVendor = PaginatedVendors["data"][number];

export const useCurrentVendorQuery = (enabled: boolean): UseQueryResult<Vendor> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["vendor"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/vendor"), { signal });
            await handleJsonApiError(response);
            return response.json();
        },
        select: vendorSelector,
        enabled,
    });
};

export const useVendorsQuery = (
    pageParams: PageParams | undefined,
    filterSearch = "",
    enabled = true,
): UseQueryResult<PaginatedVendors> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["vendors", { pageParams, filterSearch }],
        queryFn: async ({ signal }) => {
            const url = apiUrl("/vendors");
            url.searchParams.set("fields[vendor]", "name");
            url.searchParams.set("sort", "name");

            if (filterSearch.length > 0) {
                url.searchParams.set("filter[search]", filterSearch);
            }

            injectPageParams(url, pageParams);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: vendorsSelector,
        placeholderData: keepPreviousData,
        enabled,
    });
};
