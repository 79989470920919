import { useSignedObjectUrlQuery } from "@/queries/signed-object-url.ts";
import { Box, Skeleton, type SxProps } from "@mui/material";
import type { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
    path: string;
    width?: string;
    height?: string;
    alt: string;
    imageSx?: SxProps;
};

const ProtectedImage = ({ path, width, height, alt, imageSx }: Props): ReactNode => {
    const { ref, inView } = useInView();
    const objectUrlQuery = useSignedObjectUrlQuery(path, inView);

    if (objectUrlQuery.isError) {
        throw objectUrlQuery.error;
    }

    if (objectUrlQuery.isPending) {
        return <Skeleton variant="rectangular" ref={ref} width={width} height={height} />;
    }

    return (
        <Box
            component="img"
            ref={ref}
            src={objectUrlQuery.data.$links.self}
            width={width}
            height={height}
            alt={alt}
            sx={imageSx}
        />
    );
};

export default ProtectedImage;
