import FullPageSpinner from "@/components/FullPageSpinner/index.ts";
import { type Vendor, useCurrentVendorQuery } from "@/queries/vendor.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { type ReactNode, createContext, useContext, useMemo } from "react";

export type CustomerUserInformation = {
    type: "customer";
    vendor: Vendor;
};

export type EmployeeUserInformation = {
    type: "employee";
};

export type UserInformation = CustomerUserInformation | EmployeeUserInformation;

const UserInformationContext = createContext<UserInformation | null>(null);

type Props = {
    children: ReactNode;
};

const UserInformationProvider = ({ children }: Props): ReactNode => {
    const { user } = useAuth0();

    if (!user) {
        throw new Error("User is not authenticated");
    }

    const userRole = useMemo(() => {
        const roles = user["sycamore/roles"] as string[] | undefined;

        if (roles?.includes("Employee")) {
            return "employee";
        }

        return "customer";
    }, [user]);

    const vendorQuery = useCurrentVendorQuery(userRole === "customer");

    if (vendorQuery.isError) {
        throw vendorQuery.error;
    }

    if (vendorQuery.isPending && userRole === "customer") {
        return <FullPageSpinner />;
    }

    const userInformation: UserInformation = !vendorQuery.data
        ? { type: "employee" }
        : {
              type: "customer",
              vendor: vendorQuery.data,
          };

    return (
        <UserInformationContext.Provider value={userInformation}>
            {children}
        </UserInformationContext.Provider>
    );
};

export const useUserInformationContext = () => {
    const context = useContext(UserInformationContext);

    if (!context) {
        throw new Error("Context used outside UserInformationProvider");
    }

    return context;
};

export default UserInformationProvider;
