import type { ProjectDocumentTypeKey } from "@/components/Project/documents.ts";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type UploadProjectDocumentValues = {
    projectId: string;
    documentType: ProjectDocumentTypeKey;
    contentType: string;
    filename: string;
    data: Blob;
};

export const useUploadProjectDocumentMutation = (): UseMutationResult<
    void,
    Error,
    UploadProjectDocumentValues
> => {
    const queryClient = useQueryClient();
    const fetch = useAuthenticatedFetch();

    return useMutation({
        mutationFn: async (values) => {
            const { projectId, documentType, contentType, filename, data } = values;

            const response = await fetch(apiUrl(`/projects/${projectId}/documents`).toString(), {
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": contentType,
                    "X-Document-Type": documentType,
                    "X-Filename": filename,
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async (_result, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["project", values.projectId, "documents"],
            });
        },
    });
};

type DeleteProjectDocumentValues = {
    projectId: string;
    documentId: string;
};

export const useDeleteProjectDocumentMutation = (): UseMutationResult<
    void,
    Error,
    DeleteProjectDocumentValues
> => {
    const queryClient = useQueryClient();
    const fetch = useAuthenticatedFetch();

    return useMutation({
        mutationFn: async (values) => {
            const { projectId, documentId } = values;

            const response = await fetch(
                apiUrl(`/projects/${projectId}/documents/${documentId}`).toString(),
                {
                    method: "DELETE",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async (_result, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["project", values.projectId, "documents"],
            });
        },
    });
};
