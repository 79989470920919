import { projectStatusMap } from "@/components/Project/index.ts";
import PalletCard from "@/pages/ProjectsPage/ProejctDialog/ViewProjectDialog/PalletCard.tsx";
import { type Project, usePalletsQuery } from "@/queries/project.ts";
import { numberFormatter } from "@/utils/format.ts";
import { Chip, Container, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    project: Project;
};

const PalletsTab = ({ project }: Props): ReactNode => {
    const palletsQuery = usePalletsQuery(project.id);

    if (palletsQuery.isError) {
        throw palletsQuery.error;
    }

    if (palletsQuery.isPending) {
        return (
            <Container>
                <LinearProgress />
            </Container>
        );
    }

    const pallets = palletsQuery.data;

    if (pallets.length === 0) {
        return (
            <Container>
                <Typography>There are no pallets assigned to this project.</Typography>
            </Container>
        );
    }

    const projectWeight = pallets.reduce((total, pallet) => total + pallet.weight, 0);

    return (
        <Container>
            <Chip label={projectStatusMap[project.status].label} sx={{ mb: 1 }} />

            <Typography>Weight: {numberFormatter.format(projectWeight)} lbs</Typography>
            <Typography>Number of pallets: {numberFormatter.format(pallets.length)}</Typography>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
                {pallets.map((pallet) => (
                    <Grid item key={pallet.id} xs={12} sm={6} md={4} lg={3}>
                        <PalletCard project={project} pallet={pallet} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default PalletsTab;
