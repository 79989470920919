import PageParamsNavigation from "@/components/PageParamsNavigation/index.ts";
import { projectStatuses } from "@/components/Project/index.ts";
import type { ProjectStatus } from "@/components/Project/status.ts";
import SearchField from "@/components/SearchField/index.ts";
import { useUserInformationContext } from "@/components/UserInformationProvider/index.ts";
import VendorFilter from "@/pages/ProjectsPage/VendorFilter.tsx";
import { useProjectsQuery } from "@/queries/project.ts";
import type { ListVendor } from "@/queries/vendor.ts";
import { Autocomplete, LinearProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import type { PageParams } from "jsonapi-zod-query";
import { type ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";
import ProjectsList from "./ProjectsList.tsx";

const defaultPageParams: PageParams = {
    size: "50",
};

const ProjectsPage = (): ReactNode => {
    const { type } = useUserInformationContext();
    const [pageParams, setPageParams] = useState<PageParams>(defaultPageParams);
    const [filterSearch, setFilterSearch] = useState("");
    const [filterStatus, setFilterStatus] = useState<ProjectStatus | null>(null);
    const [filterVendor, setFilterVendor] = useState<ListVendor | null>(null);

    const projectsQuery = useProjectsQuery(
        pageParams,
        filterSearch,
        filterStatus?.key ?? null,
        filterVendor?.id ?? null,
    );

    if (projectsQuery.isError) {
        throw projectsQuery.error;
    }

    if (projectsQuery.isPending) {
        return (
            <>
                <Typography variant="h6" mb={2}>
                    Projects
                </Typography>
                <LinearProgress />
            </>
        );
    }

    const projects = projectsQuery.data.data;

    return (
        <>
            <Typography variant="h6" mb={2}>
                Projects
            </Typography>

            <Paper sx={{ mb: 2, p: 2 }}>
                <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                    <SearchField
                        label="Search"
                        value={filterSearch}
                        onChange={(value) => {
                            setFilterSearch(value);
                            setPageParams(defaultPageParams);
                        }}
                        sx={{ flexGrow: 1 }}
                    />
                    {type === "employee" && (
                        <VendorFilter
                            value={filterVendor}
                            onChange={(vendor) => setFilterVendor(vendor)}
                            sx={{ width: { xs: "auto", md: 250 } }}
                        />
                    )}
                    <Autocomplete
                        value={filterStatus}
                        onChange={(_event, value) => {
                            setFilterStatus(value);
                            setPageParams(defaultPageParams);
                        }}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Status" />}
                        getOptionLabel={(option) => option.label}
                        options={projectStatuses}
                        sx={{ width: { xs: "auto", md: 250 } }}
                    />
                </Stack>
            </Paper>

            <Paper>
                {projects.length === 0 ? (
                    <Typography p={2}>
                        {filterSearch !== "" || filterStatus
                            ? "No project matched your filter criteria."
                            : "No projects found."}
                    </Typography>
                ) : (
                    <ProjectsList projects={projects} showVendor={type === "employee"} />
                )}
            </Paper>

            {(projectsQuery.data.pageParams.prev || projectsQuery.data.pageParams.next) && (
                <PageParamsNavigation
                    pageParams={projectsQuery.data.pageParams}
                    onChange={setPageParams}
                />
            )}

            <Outlet />
        </>
    );
};

export default ProjectsPage;
