import { useSignedObjectUrlQuery } from "@/queries/signed-object-url.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

type UseDocumentDownloadResult = {
    downloading: boolean;
    startDownload: () => void;
};

const useDocumentDownload = (projectId: string, documentId: string): UseDocumentDownloadResult => {
    const [downloading, setDownloading] = useState(false);
    const path = `/projects/${projectId}/documents/${documentId}`;
    const objectUrlQuery = useSignedObjectUrlQuery(path, false);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!downloading || objectUrlQuery.isPending) {
            return;
        }

        setDownloading(false);

        if (objectUrlQuery.isError) {
            enqueueSnackbar("Failed to retrieve document", { variant: "error" });
            return;
        }

        const url = objectUrlQuery.data.$links.self;

        // window.open is a blocking call and needs to be called outside of hook context
        // @see https://github.com/facebook/react/issues/17355
        window.requestAnimationFrame(() => {
            window.open(url, "noreferrer");
        });
    }, [
        downloading,
        objectUrlQuery.data,
        objectUrlQuery.isPending,
        objectUrlQuery.isError,
        enqueueSnackbar,
    ]);

    const startDownload = async () => {
        setDownloading(true);
        await queryClient.resetQueries({ queryKey: ["signed-object-url", path] });
        await objectUrlQuery.refetch();
    };

    return { downloading, startDownload };
};

export default useDocumentDownload;
