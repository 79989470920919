import ProtectedImage from "@/components/ProtectedImage/index.ts";
import type { Vendor } from "@/queries/vendor.ts";
import { Card, CardContent, CardHeader, CardMedia, Link, Typography } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    accountManager: Vendor["accountManager"];
};

const AccountManagerCard = ({ accountManager }: Props): ReactNode => {
    return (
        <Card sx={{ flexGrow: 1 }}>
            <CardHeader title="Account Manager" subheader={accountManager.fullName} />
            <CardContent>
                <Typography>
                    Email:{" "}
                    <Link href={`mailto:${accountManager.emailAddress}`}>
                        {accountManager.emailAddress}
                    </Link>
                    <br />
                    Phone:{" "}
                    <Link href={`tel:${accountManager.phoneNumber.format("E.164")}`}>
                        {accountManager.phoneNumber.formatNational()}
                    </Link>
                </Typography>
            </CardContent>
            <CardMedia
                component={ProtectedImage}
                width="100%"
                path="/vendor/account-manager/photo"
                alt={`Photo of ${accountManager.fullName}`}
                imageSx={{ display: "block" }}
            />
        </Card>
    );
};

export default AccountManagerCard;
