export type ProjectStatus = {
    key: string;
    label: string;
};

export const projectStatuses = [
    { key: "confirmed", label: "Confirmed" },
    { key: "scheduled", label: "Scheduled" },
    { key: "in_transit", label: "In Transit" },
    { key: "received", label: "Received" },
    { key: "sorted", label: "Sorted" },
    { key: "awaiting_release", label: "Awaiting Release" },
    { key: "in_processing", label: "In Processing" },
    { key: "processing_complete", label: "Processing Complete" },
    { key: "quality_control", label: "QC" },
    { key: "approved", label: "Approved" },
    { key: "settlement", label: "Settlement" },
    { key: "reopened", label: "Reopened" },
    { key: "complete", label: "Complete" },
] as const satisfies ProjectStatus[];

export type ProjectStatusKey = (typeof projectStatuses)[number]["key"];

export const projectStatusKeys = projectStatuses.map((attributes) => attributes.key);
export const projectStatusMap = Object.fromEntries(
    projectStatuses.map((status) => [status.key, status]),
) as Record<ProjectStatusKey, ProjectStatus>;
