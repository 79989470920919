import { type Project, useDocumentsQuery } from "@/queries/project.ts";
import { Container, LinearProgress, List, Paper, Typography } from "@mui/material";
import type { ReactNode } from "react";
import DocumentListItem from "./DocumentListItem.tsx";

type Props = {
    project: Project;
};

const DocumentsTab = ({ project }: Props): ReactNode => {
    const documentsQuery = useDocumentsQuery(project.id);

    if (documentsQuery.isError) {
        throw documentsQuery.error;
    }

    if (documentsQuery.isPending) {
        return (
            <Container>
                <LinearProgress />
            </Container>
        );
    }

    const documents = documentsQuery.data;

    if (documents.length === 0) {
        return (
            <Container>
                <Typography>No documents have been uploaded for this project yet.</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="xs" component={Paper}>
            <List>
                {documents.map((document) => (
                    <DocumentListItem key={document.id} document={document} project={project} />
                ))}
            </List>
        </Container>
    );
};

export default DocumentsTab;
