import { type PaletteMode, createTheme } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { type ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";

export type ThemeMode = PaletteMode | "system";

type ThemeContextValue = {
    switchMode: (mode: ThemeMode) => void;
    mode: ThemeMode;
};

const ThemeContext = createContext<ThemeContextValue | null>(null);

const storageKey = "syc_theme_mode";

const getStoredMode = (): ThemeMode => {
    const storedMode = window.localStorage.getItem(storageKey);
    return storedMode === "light" || storedMode === "dark" ? storedMode : "system";
};

type Props = {
    children: ReactNode;
};

const ThemeProvider = ({ children }: Props): ReactNode => {
    const [themeMode, setThemeMode] = useState(getStoredMode);

    const paletteMode = useMemo((): PaletteMode => {
        if (themeMode === "system") {
            return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        }

        return themeMode;
    }, [themeMode]);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: paletteMode,
                    primary: {
                        main: paletteMode === "light" ? "#005a70" : "#03a298",
                    },
                    secondary: {
                        main: "#4cb748",
                    },
                },
            }),
        [paletteMode],
    );

    const switchMode = useCallback((mode: ThemeMode) => {
        setThemeMode(mode);
        window.localStorage.setItem(storageKey, mode);
    }, []);

    const value = useMemo(
        () => ({
            mode: themeMode,
            switchMode,
        }),
        [themeMode, switchMode],
    );

    return (
        <ThemeContext.Provider value={value}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error("Context used outside ThemeProvider");
    }

    return context;
};

export default ThemeProvider;
