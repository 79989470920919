import { useUserInformationContext } from "@/components/UserInformationProvider/index.ts";
import { useProjectQuery } from "@/queries/project.ts";
import { Backdrop, CircularProgress } from "@mui/material";
import { type ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManageDocumentsDialog from "./ManageDocumentsDialog/index.ts";
import ViewProjectDialog from "./ViewProjectDialog/index.ts";

type Params = {
    projectId: string;
};

const ProjectDialog = (): ReactNode => {
    const { projectId } = useParams<Params>();

    if (!projectId) {
        throw new Error("Project ID missing");
    }

    const [open, setOpen] = useState(true);
    const projectQuery = useProjectQuery(projectId);
    const { type } = useUserInformationContext();
    const navigate = useNavigate();
    const [forceCustomerPerspective, setForceCustomerPerspective] = useState(false);

    if (projectQuery.isError) {
        throw projectQuery.error;
    }

    if (projectQuery.isPending) {
        return (
            <Backdrop open sx={{ zIndex: 1500 }}>
                <CircularProgress />
            </Backdrop>
        );
    }

    const project = projectQuery.data;
    const dialogProps = {
        project,
        open,
        onClose: () => {
            setOpen(false);
        },
        onExited: () => {
            navigate("/projects");
        },
    };

    return type === "customer" || forceCustomerPerspective ? (
        <ViewProjectDialog {...dialogProps} />
    ) : (
        <ManageDocumentsDialog
            {...dialogProps}
            viewCustomerPerspective={() => {
                setForceCustomerPerspective(true);
            }}
        />
    );
};

export default ProjectDialog;
