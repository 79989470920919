import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const signedObjectUrlSelector = createDataSelector(
    createResourceSelector({
        type: "signed_object_url",
        attributesSchema: z.object({
            expiresIn: z.number(),
        }),
        linksSchema: z.object({
            self: z.string(),
        }),
    }),
);

const signedObjectUrlsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "signed_object_url",
        attributesSchema: z.object({
            expiresIn: z.number(),
        }),
        linksSchema: z.object({
            self: z.string(),
        }),
    }),
);

export type SignedObjectUrl = ReturnType<typeof signedObjectUrlSelector>;

export const useSignedObjectUrlQuery = (
    path: string,
    enabled: boolean,
): UseQueryResult<SignedObjectUrl> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["signed-object-url", path],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl(path), { signal });
            await handleJsonApiError(response);
            return response.json();
        },
        select: signedObjectUrlSelector,
        enabled,
    });
};

export const useSignedObjectUrlsQuery = (
    path: string,
    enabled: boolean,
): UseQueryResult<SignedObjectUrl[]> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["signed-object-urls", path],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl(path), { signal });
            await handleJsonApiError(response);
            return response.json();
        },
        select: signedObjectUrlsSelector,
        enabled,
    });
};
