import { useUserInformationContext } from "@/components/UserInformationProvider/index.ts";
import type { ReactNode } from "react";
import { Navigate } from "react-router-dom";

const HomePage = (): ReactNode => {
    const userInformation = useUserInformationContext();

    if (userInformation.type === "employee") {
        return <Navigate to="/projects" />;
    }

    return <Navigate to="/dashboard" />;
};

export default HomePage;
