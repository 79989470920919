import type { ProjectDocumentTypeKey } from "@/components/Project/documents.ts";
import { projectDocumentTypes, projectStatusMap } from "@/components/Project/index.ts";
import { useUploadProjectDocumentMutation } from "@/mutations/project.ts";
import { type Project, useDocumentsQuery } from "@/queries/project.ts";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    LinearProgress,
    List,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { fileOpen } from "browser-fs-access";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import DocumentListItem from "./DocumentListItem.tsx";

type Props = {
    project: Project;
    open: boolean;
    onClose: () => void;
    onExited: () => void;
    viewCustomerPerspective: () => void;
};

const ManageDocumentsDialog = ({
    project,
    open,
    onClose,
    onExited,
    viewCustomerPerspective,
}: Props): ReactNode => {
    const documentsQuery = useDocumentsQuery(project.id);
    const uploadMutation = useUploadProjectDocumentMutation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const menuState = usePopupState({
        variant: "popover",
        popupId: `project-upload-${project.id}`,
    });

    if (documentsQuery.isError) {
        throw documentsQuery.error;
    }

    const handleDocumentSelect = (
        data: Blob,
        documentType: ProjectDocumentTypeKey,
        contentType: string,
        filename: string,
    ) => {
        uploadMutation.mutate({
            projectId: project.id,
            documentType,
            contentType,
            filename,
            data,
        });
    };

    const selectDocument = async (documentType: ProjectDocumentTypeKey) => {
        const fileWithHandle = await fileOpen();
        const reader = new FileReader();

        reader.addEventListener("load", () => {
            handleDocumentSelect(
                new Blob([reader.result as ArrayBuffer]),
                documentType,
                fileWithHandle.type,
                fileWithHandle.name,
            );
        });

        reader.readAsArrayBuffer(fileWithHandle);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionProps={{ onExited }}
            maxWidth="xs"
            fullWidth
            fullScreen={fullScreen}
        >
            <DialogTitle>Manage Documents</DialogTitle>
            <DialogContent>
                <Typography mb={1}>
                    {project.vendor.name} - {project.name}
                </Typography>
                <Chip label={projectStatusMap[project.status].label} sx={{ mb: 2 }} />
            </DialogContent>
            <Divider />

            {!documentsQuery.data && <LinearProgress />}
            {documentsQuery.data && (
                <List disablePadding>
                    {documentsQuery.data.map((document) => (
                        <DocumentListItem key={document.id} document={document} project={project} />
                    ))}
                </List>
            )}
            <DialogActions>
                <Button onClick={viewCustomerPerspective} color="secondary" sx={{ mr: "auto" }}>
                    Customer view
                </Button>
                <Button {...bindTrigger(menuState)}>Upload document</Button>
                <Button onClick={onClose}>Close</Button>

                <Menu {...bindMenu(menuState)}>
                    {projectDocumentTypes.map((documentType) => (
                        <MenuItem
                            key={documentType.key}
                            onClick={() => {
                                menuState.close();
                                void selectDocument(documentType.key);
                            }}
                        >
                            {documentType.label}
                        </MenuItem>
                    ))}
                </Menu>
            </DialogActions>
        </Dialog>
    );
};

export default ManageDocumentsDialog;
